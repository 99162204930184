<template>
  <div>
    <Alert />
    <div class="titre-container">
      <h1 class="mt-0 fs-b fs-2500 color-pr">Services complémentaires</h1>
    </div>

    <div class="fr-container">
      <div class="fr-grid-row fr-grid-row--gutters">
        <div v-for="(item, index) in sortedArray" :key="index">
          <div
            class="margin-bloc rs-w-100"
            @click="extendClickToParent(item, index, $event)"
          >
            <div class="rectangle fr-tile rs-w-320" :title="setTitle(item)">
              <div class="tile-header">
                <div class="pictogram">
                  <span
                    class="color-pr ri-2x"
                    :class="'ri-' + item.codeIcone"
                  />
                </div>
                <h4 class="tile-title fr-tile__title fs-b">
                  {{ item.libelle }}
                </h4>
              </div>
              <div class="tile-body fr-tile__body">
                <p class="text-gray">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
          <div class="icone-services-compl">
            <DeclarationAction
              :actions="getAction(item)"
              class="big-icon no-margin-right"
              :label="'ouverture du lien ' + item.cible"
              :eventIndex="index"
            />
          </div>
        </div>
      </div>
      <div v-if="noData">
        <NonHabilite
          classTexte="no-service col-md-8 col-12"
          classImage="col-md-4 col-12"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SkipLinkMixin from '../mixins/skip-link-mixin';

import Alert from '../shared/Alert';
import DeclarationAction from '../components/DeclarationAction';

import { EVENT_BUS_PARENT_SERVICES_COMPLEMENTAIRES } from '../constantes';
import NonHabilite from '../views/NonHabilite';

export default {
  components: {
    Alert,
    DeclarationAction,
    NonHabilite
  },
  mixins: [SkipLinkMixin],
  data() {
    return {
      componentPath: {}
    };
  },
  computed: {
    ...mapGetters(['siren']),
    ...mapGetters('servicesComplementaires', ['servicesComplementaires']),

    sortedArray() {
      const copy = [...this.servicesComplementaires];
      return copy.sort((a, b) => a.numeroOrdre - b.numeroOrdre);
    },
    noData() {
      //la vérification des habilitations est faite par le back/courtier
      return !(
        this.servicesComplementaires && this.servicesComplementaires.length > 0
      );
    }
  },
  methods: {
    ...mapActions('servicesComplementaires', ['fetchServicesComplementaires']),

    getAction(item) {
      return [
        {
          typeAction: item.typeAction,
          typeComposant: 'LIEN',
          icone: 'EXTERNAL-LINK',
          positionIcone: '',
          actif: true,
          libelle: '',
          cible: item.cible,
          verifSiret: false
        }
      ];
    },
    extendClickToParent(item, index, event) {
      this.emitter.emit(EVENT_BUS_PARENT_SERVICES_COMPLEMENTAIRES, {
        item: item,
        index: index,
        event: event
      });
    },
    setTilesHeight() {
      const divElements = document.querySelectorAll('.fr-tile');
      let maxHeight = 0;

      for (const divElement of divElements) {
        const elemHeight = divElement.offsetHeight;

        if (elemHeight > maxHeight) {
          maxHeight = elemHeight;
        }
      }
      for (const divElement of divElements) {
        divElement.style.height = maxHeight + 'px';
      }
    },
    setTitle(item) {
      if (item.typeAction !== 'IFRAME_DGFIP') {
        return `${item.libelle} - Nouvel onglet`;
      }
    }
  },

  async mounted() {
    await this.fetchServicesComplementaires(this.siren);

    this.setTilesHeight();
  }
};
</script>

<style lang="scss" scoped>
a {
  height: 100%;
}

.rectangle {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0 6px 30px 0 !important;
  border: 1px solid #ddd;
  max-width: 357px;
  padding: 0.5em 0.5em 0px;
  width: 357px;
  cursor: pointer;
  position: relative;
  height: 319px;
}
.rectangle:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 30px 0px !important;
}

:root:not([data-fr-scheme='dark']) .fr-tile {
  --color-hover: white;
  --color-active: white;
}

.fr-tile {
  padding: 1.5rem;
  flex-direction: column;
}

.fr-grid-row--gutters {
  margin-bottom: 2.75rem;
}

.fr-container {
  padding-left: 0;
  @media (min-width: 990px) {
    margin-top: -2rem;
  }
}

.tile-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.tile-header .pictogram {
  align-self: flex-start;
}

.pictogram {
  padding-right: 1em;
}

.fr-tile__title a {
  font-weight: normal;
}

.tile-title {
  font-weight: normal;
  width: 100%;
}

.titre-container {
  margin-bottom: 0 !important;
}

.tile-body {
  max-width: 357px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  padding: 0 !important;
}

.margin-bloc {
  margin: 16px;
}

.text-gray {
  font-size: 0.875rem;
  text-align: left;
  margin-top: 1rem;
}

@media only screen and (max-width: 1200px) {
  .rs-w-320 {
    width: 320px;
  }
  .margin-bloc {
    margin-left: 0px;
    margin-top: 16px;
  }
  .rs-w-100 {
    width: 100%;
  }
  .rs-w-100 {
    width: 100%;
  }
}

.icone-services-compl {
  position: relative;
  top: -4.5rem;
  right: 2.5rem;
}
</style>
